import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import BlockBanner from 'components/blocks/BlockBanner'
import BlockTitleOnly from 'components/blocks/BlockTitleOnly'
import BlockTextImageOverflow from 'components/blocks/BlockTextImageOverflow'
import BlockImageOnly from 'components/blocks/BlockImageOnly'
import BlockTextColumnsIcon from 'components/blocks/BlockTextColumnsIcon'
import { content, filterContentByLocale } from 'content/content.js'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const JoinPage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { join, footer } = localeContent
  const {
    banner,
    titleOnly,
    textImage,
    textImage2,
    imageOnly,
    textColumnsIcon,
  } = join

  let seoContent = {
    title: {
      en: `Join`,
      zh: `加入我们`,
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div>
      <div className='page'>
        <Seo content={seoContent} />
        <div className='bg-gradient'>
          <BlockBanner content={banner} />
          <BlockTitleOnly content={titleOnly} />
          <BlockTextImageOverflow content={textImage} />
          <BlockTextImageOverflow content={textImage2} isReversed={true} />
          <BlockImageOnly content={imageOnly} />
          <BlockTextColumnsIcon content={textColumnsIcon} />
          <Footer content={footer.join} />
        </div>
      </div>
    </div>
  )
}

export default JoinPage
