import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockTextImage = ({ content, isReversed }) => {
  const { highlight, body, image } = content

  return (
    <section className='block-text-image-overflow'>
      <Inview className='container'>
        <div className={`main ${isReversed ? 'reversed' : ''}`}>
          <div className='text fade-in up'>
            {highlight && <h4>{highlight}</h4>}
            <div className='body' dangerouslySetInnerHTML={{ __html: body }} />
          </div>
          <div className='image fade-in up'>
            <Image filename={image} />
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default BlockTextImage
