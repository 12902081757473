import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockTextColumnsIcon = ({ content }) => {
  const { icon, title, excerpt, body } = content
  return (
    <section className='block-text-columns-icon'>
      <Inview className='container'>
        <div className='left fade-in up'>
          <Image className='icon' filename={icon} />
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
        <div className='right fade-in up stagger-500'>
          <div className='body' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </Inview>
    </section>
  )
}

export default BlockTextColumnsIcon
