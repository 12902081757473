import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockImageOnly = ({ content }) => {
  const { image } = content
  return (
    <section className='block-image-only'>
      <Inview className='container'>
        <div className='image fade-in up'>
          <Image filename={image} />
        </div>
      </Inview>
    </section>
  )
}

export default BlockImageOnly
